import { render, staticRenderFns } from "./SiteFooter.vue?vue&type=template&id=2a99f38c"
import script from "./SiteFooter.vue?vue&type=script&lang=js"
export * from "./SiteFooter.vue?vue&type=script&lang=js"
import style0 from "./SiteFooter.vue?vue&type=style&index=0&id=2a99f38c&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BootstrapIcon: require('/var/www/html/bielbit/www2022/upd/components/BootstrapIcon.vue').default,ExternalLink: require('/var/www/html/bielbit/www2022/upd/components/ExternalLink.vue').default,TelTo: require('/var/www/html/bielbit/www2022/upd/components/TelTo.vue').default,MailTo: require('/var/www/html/bielbit/www2022/upd/components/MailTo.vue').default,InternalLink: require('/var/www/html/bielbit/www2022/upd/components/InternalLink.vue').default})
