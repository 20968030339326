
export default {
  name: 'LinkTo',
  props: {
    type: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    tab: {
      type: String,
      required: false,
      default: '_self',
    },
  },
  methods: {
    goTo() {
      // location.href = this.type + ':' + this.target
      window.open(this.type + ':' + this.target, this.tab)
    },
  },
}
