export default {
  async asyncData({ app }) {
    let posts = []
    try {
      posts = await app.$wp.posts().perPage(4).page(1)
      for (let index = 0; index < posts.length; index++) {
        const post = posts[index]
        const images = post.content.rendered.match(
          /<img[^>]* src=["|']([^'"]*)["|'][^>]*>/gi,
        )
        if (images) {
          for (let index = 0; index < images.length; index++)
            post.content.rendered = post.content.rendered.replace(
              images[index],
              '',
            )
          for (let index = 0; index < images.length; index++)
            images[index] = images[index]
              .match(/src=["|'].*\..{3,4}["|']/gi)[0]
              .replace(/src=/gi, '')
              .replace(/"/g, '')
              .replace(/'/g, '')
              .replace(
                'https://projektblog.bielbit.pl/wp-content/',
                '/projekt-wp-content/',
              )
          posts[index].images = images
        }
        const links = post.content.rendered.match(
          /<a\b[^>]*>([\s\S]*?)<\/a>/gim,
        )
        const newLinks = []
        if (links) {
          for (let index = 0; index < links.length; index++) {
            const element = links[index]
            const anchorArray = element.match(/<a\b[^>]*>([\s\S]*?)<\/a>/i)
            const anchor = anchorArray[1].trim()
            newLinks.push(
              (!element.includes('class=')
                ? element.replace('href=', 'class="bbit-link" href=')
                : element.replace('class="', 'class="bbit-link')
              ).replace(
                'href=',
                !element.includes('//bielbit.pl/') &&
                  !element.includes('//www.bielbit.pl/')
                  ? 'title="Przejdź do strony ' +
                      anchor +
                      '" rel="nofollow" href='
                  : 'title="Przejdź do strony ' + anchor + '" href=',
              ),
            )
          }
          for (let index = 0; index < links.length; index++)
            post.content.rendered = post.content.rendered.replace(
              links[index],
              newLinks[index],
            )
        }
      }
    } catch (error) {
      console.error(error)
    }
    return {
      posts,
    }
  },
}
