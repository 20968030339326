
export default {
  name: 'ExternalLink',
  props: {
    anchor: {
      type: String,
      required: true,
    },
    page: {
      type: String,
      required: true,
    },
  },
}
