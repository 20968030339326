
export default {
  name: 'SiteFooter',
  props: {
    number1: {
      type: String,
      required: true,
    },
    email1: {
      type: String,
      required: true,
    },
    number2: {
      type: String,
      required: false,
      default: '',
    },
    isNumber2: {
      type: Boolean,
      required: false,
      default: false,
    },
    email2: {
      type: String,
      required: false,
      default: '',
    },
    isEmail2: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNumber3: {
      type: Boolean,
      required: false,
      default: false,
    },
    number3: {
      type: String,
      required: false,
      default: '',
    },
    isEmail3: {
      type: Boolean,
      required: false,
      default: false,
    },
    email3: {
      type: String,
      required: false,
      default: '',
    },
  },
}
